import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import onClickOutside from "react-onclickoutside"
import { decode } from "html-entities"

function ChooseExam({ data }) {
  const [nav, showNav] = useState(false)

  ChooseExam.handleClickOutside = () => showNav(false)

  const [input, setInput] = useState("")
  let searchList = data.exams.edges.map((item, index) => item)

  const handleChange = e => {
    e.preventDefault()
    setInput(e.target.value)
  }

  function removeAccentedChars(input) {
    input = input.toLowerCase()

    let output = ""
    for (var i = 0; i < input.length; i++) {
      if (input.charAt(i) === "a") output = output + "[aàáâãäåæ]"
      else if (input.charAt(i) === "c") output = output + "[cç]"
      else if (input.charAt(i) === "e") output = output + "[eèéêëæ]"
      else if (input.charAt(i) === "i") output = output + "[iìíîï]"
      else if (input.charAt(i) === "n") output = output + "[nñ]"
      else if (input.charAt(i) === "o") output = output + "[oòóôõöø]"
      else if (input.charAt(i) === "s") output = output + "[sß]"
      else if (input.charAt(i) === "u") output = output + "[uùúûü]"
      else if (input.charAt(i) === "y") output = output + "[yÿ]"
      else output = output + input.charAt(i)
    }
    return output
  }

  if (input.length > 0) {
    searchList = searchList.filter(i => {
      return i.node.brand.toLowerCase().match(removeAccentedChars(input))
    })
  }

  useEffect(() => {
    setInput("")
  }, [nav])

  useEffect(() => {
    document.addEventListener("keyup", searchKeyPressHandler)
    return () => document.removeEventListener("keyup", searchKeyPressHandler)
  }, [])

  function searchKeyPressHandler(e) {
    if (e.keyCode === 27) {
      showNav(false)
    }
  }

  return (
    <Wrapper>
      <Hamburger nav={nav} onClick={() => showNav(!nav)}>
        <div />
        <div />
        <div />
      </Hamburger>

      <Navigation nav={nav}>
        <h2>exams alphabetically</h2>

        <SearchInput>
          <div className="input-wrapper">
            <input
              onChange={handleChange}
              value={input}
              placeholder="&#128269;"
              spellCheck="false"
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => {
                e.target.placeholder = `${decode("&#128269;")}`
              }}
            />
            <span className="input-highlight">{input}</span>
          </div>
        </SearchInput>

        <ul>
          {searchList.length > 0 ? (
            searchList.map((item, index) => {
              return (
                <li key={item.node.id}>
                  <Link to={`/${item.node.slug}`}>
                    &#8278; {item.node.brand}
                  </Link>
                </li>
              )
            })
          ) : (
            <p>No results.</p>
          )}
        </ul>
      </Navigation>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  z-index: 1;
`

const SearchInput = styled.div`
  .input-wrapper {
    position: relative;
    width: 66%;
    margin: 0 auto 20px;
  }

  .input-highlight {
    font-size: 1.6rem;
    user-select: none;
    line-height: 2;
    border-top: 3px solid #eeeeee;
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 100%;
    height: 0;
    color: transparent;
    overflow: hidden;
  }

  input {
    width: 100%;
    min-width: 100%;
    padding: 0;
    border-radius: 0;
    line-height: 2;
    background-color: transparent;
    color: white;
    font-size: 1.6rem;
    border: none;
    outline: none;
    border-bottom: 3px solid #eeeeee;

    &::placeholder {
      color: #eeeeee;
    }

    &:focus {
      border-bottom: 3px solid white;
      color: white;
      + .input-highlight {
        border-top: 3px solid #424242;
      }
    }
  }
`
const Hamburger = styled.button`
  z-index: 5;

  cursor: pointer;
  border: 0;
  margin: 0;
  background-color: transparent;

  height: 1.5rem;

  display: flex;
  flex-direction: column;

  justify-content: space-around;
  outline: none;

  div {
    width: 1.5rem;
    height: 0.25rem;
    background: black;
    border-radius: 2px;
    transform-origin: 1px;
    position: relative;

    transition: opacity 0.25s, transform 0.25s;

    :first-child {
      transform: ${({ nav }) => (nav ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ nav }) => (nav ? "0" : "1")};
    }

    :nth-child(3) {
      transform: ${({ nav }) => (nav ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  display: grid;
  /* grid-template-rows: 160px 1fr 40px; */
  grid-template-rows: 160px auto 1fr 40px;

  /* font-family: var(--montserrat); */
  font-family: "Montserrat", sans-serif;
  text-align: center;

  min-height: 100vh;
  height: auto;
  width: 70%;

  position: absolute;
  top: 0;
  right: 0;

  position: ${({ nav }) => (nav ? "absolute" : "fixed")};

  transition: 0.5s;
  transform: ${({ nav }) => (nav ? "translateX(0)" : "translateX(100%)")};

  /* background-color: rgba(35, 154, 135, 0.45); */
  backdrop-filter: blur(8px); /* not on mozilla */

  background-color: #fcb712;

  @media (min-width: 500px) {
    width: 60vw;
  }

  @media (min-width: 700px) {
    width: 50vw;
  }

  @media (min-width: 900px) {
    width: 40vw;
  }

  @media (min-width: 1100px) {
    width: 30vw;
  }

  ul {
    list-style-type: none;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    height: auto;

    align-self: flex-start;
  }

  li {
    margin: 1rem;
    text-align: left;
  }

  a {
    text-decoration: none;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    transition: 0.2s;

    :hover {
      color: white;
    }
  }

  h2 {
    @media (max-width: 560px) {
      font-size: 1.6rem;
    }

    width: 90%;
    margin: 0 auto;
    text-align: center;
    /* font-family: var(--poiret); */
    font-family: "Poiret One", sans-serif;
    font-size: 2.5rem;

    letter-spacing: 4px;

    align-self: flex-end;
    align-self: center;
  }

  p {
    width: 90%;
    margin: 0 auto;
  }

  span {
    font-size: 1.2rem;
    /* font-family: var(--poiret); */
    font-family: "Poiret One", sans-serif;
  }
`
const clickOutsideConfig = {
  handleClickOutside: () => ChooseExam.handleClickOutside,
  excludeScrollbar: true,
}

export default onClickOutside(ChooseExam, clickOutsideConfig)
