import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import "./layout.css"

import { createGlobalStyle } from "styled-components"
import styled from "styled-components"

import { TinyButton as ScrollUpButton } from "react-scroll-up-button"

import ChooseExam from "../components/ChooseExam"
import Logo from "../components/Logo"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Top>
        <Logo />
        <ManifestLink>
          <Link to="/manifest">
            <p>&#8278;manifesto</p>
          </Link>
        </ManifestLink>
        <StaticQuery
          query={graphql`
            {
              exams: allContentfulExam(sort: { fields: brand, order: ASC }) {
                edges {
                  node {
                    id
                    brand
                    slug
                    published(formatString: "MMMM Do, Y")
                  }
                }
              }
            }
          `}
          render={data => {
            return <ChooseExam data={data} />
          }}
        />
      </Top>

      {children}
      <ScrollUpButton
        style={{
          backgroundColor: "transparent",
          fill: "#becc2f",
          outline: "none",
        }}
      />
    </>
  )
}

const Top = styled.div`
  max-width: 90%;
  margin: 1rem auto 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ManifestLink = styled.div`
  a {
    color: black;
    text-decoration: none;
    /* font-family: var(--poiret); */
    font-family: "Poiret One", sans-serif;

    background-color: blue;
    font-size: 2rem;
    font-size: 1.8rem;

    :hover {
      font-weight: bold;
    }
  }
`

const GlobalStyle = createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  background: #becc2f;
  color: white;
}

body, html {
  max-width: 980px;
  margin: 0 auto;

  overflow-x: hidden;
}
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
